<!--
 * @Descripttion: 项目记录
 * @version: 1.0
 * @Company: ZYZN
 * @Author: Niklaus
 * @Date: 2021-01-06 17:22:43
-->
<template>
	<div class="bg vh">
		<van-nav-bar title="项目管理" left-arrow @click-left="$router.push('/mine')" />

		<van-search v-model="queryParam.projectName" shape="round" show-action placeholder="请输入搜索关键词" @clear="onClear">
			<template #action>
				<h3 class="fc" @click="searchProject">搜索</h3>
			</template>
		</van-search>

		<!-- 无数据 -->
		<div class="pad" v-if="noData">
			<div class="whiteBg pad flex-col">
				<img src="/static/img/null/project.png" class="div7">
				<h3 class="f45">无项目信息</h3>
				<p class="gray2 mar-t2">请去专家抽取功能创建项目并抽取专家。</p>
				<button class="addBtn" @click="$router.push('/step1')">去创建</button>
			</div>
		</div>

		<!-- 请求错误 -->
		<div class="pad" v-if="requestError">
			<div class="whiteBg pad flex-col">
				<img src="/static/img/null/null.png" class="div7">
				<h3 class="f45">加载失败</h3>
				<p class="gray2 mar-t2">出现了未知错误，请刷新页面。</p>
				<button class="addBtn" @click="refreshPage">刷新页面</button>
			</div>
		</div>
		<div class="pad-row">
			<van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
				<div class="whiteBg" v-for="(item,index) in list" :key="item.id" @click="goProjectInfo(item)">
					<!--
                      icon-eye : 允许操作员查看标志
                      icon-eye-close : 禁止操作员查看
                    -->
					<span v-if="item.projectStatus =='00'" class="rightTop wait">待抽取</span>
					<span v-else-if="item.projectStatus =='01'" class="rightTop doing">抽取中</span>
					<span v-else-if="item.projectStatus =='02'" class="rightTop over">抽取完成</span>
					<span v-else class="rightTop abolish">已终止</span>

					<h3 class="flex-item">
						<i class=" wh06 mar-r2" :class="{'icon-eye' : item.showFlag ==1,'icon-eye-close': item.showFlag ==0}"></i>
						<p class="van-ellipsis">{{ item.projectName }}</p>
					</h3>
					<p class="gray flex-between">
						<span class="flex1 van-ellipsis">
							采购单位名称：{{item.purchaseOrg}}
						</span>
						<i class="icon-arrow wh06"></i>
					</p>
					<p class="gray">评审时间：{{item.reviewDatetime}}</p>
				</div>
			</van-list>
		</div>

	</div>
</template>

<script>
	import {
		Search,
		List
	} from 'vant'
	export default {
		data() {
			return {
				searchV: '',
				pageSize: 10,
				pageIndex: 1,
				loading: false,
				finished: false,
				list: [],
				// 是否有数据：默认为 有
				noData: false,
				// 请求错误 默认 false
				requestError: false,
				queryParam: {
					orgId: "",
					projectName: ''
				},
				pageParams: {
					pageNo: 1,
					pageSize: 10
				},
				workweixin: false,				//企业微信
				openUserid:	"",

			}
		},
		components: {
			[List.name]: List,
			[Search.name]: Search
		},
		created() {
			//企微微信标志
			this.workweixin = this.$ls.get("expert_workweixin_flag")&&"1"==this.$ls.get("expert_workweixin_flag")
			if(this.workweixin){
				this.openUserid = this.$ls.get("expert_workweixin_user").open_userid
			}
			//获取公司信息
			if (this.$ls.get("edCurrentOrg")) {
				this.queryParam.orgId = this.$ls.get("edCurrentOrg").id;
			} else {
				this.queryParam.orgId = 'd6f2e5258fe94834b1e8e7661a28fdc7';
			}

			this.$ls.remove("tempRoute");
		},
		methods: {
			onLoad() {
				this.selectProject();
			},
			//查询 项目列表
			selectProject() {
				var params = Object.assign({}, this.queryParam)
				params.pageNo = this.pageParams.pageNo;
				params.pageSize = this.pageParams.pageSize;
				params.openid = this.workweixin?this.openUserid:this.$ls.get("openid")
				params.adminFlag = this.$ls.get('edCurrentUser').adminFlag
				this.$ajax({
					methods: 'get',
					url: '/gateway/ed/wx/wxEdProjectController/list/',
					headers: {
						'X-Access-Token': this.$ls.get("token")
					},
					params: params,
				}).then(res => {
					if (res.data.success) {
						let total = res.data.result.total;
						if (total > this.list.length) {
							this.list = this.list.concat(res.data.result.records);
							this.finished = false;
							this.pageParams.pageNo++
						} else {
							this.finished = true;
						}
						if (this.list.length == 0) this.noData = true
						this.loading = false;
					} else {
						this.$toast.fail("失败")
						this.requestError = true
						console.log(res.data.message)
					}
				});
			},
			//跳转项目详情页面
			goProjectInfo(item) {
				this.$ls.set("currentProjectInfo", item, 10 * 60 * 1000);
				//00待抽取 01抽取中 02抽取完成 03已终止
				if ('00' == item.projectStatus) {
					this.$ls.set("tempRoute", "record")
					this.$router.push({
						name: 'step1',
						query: {
							fromPage: 'unextract',
							projectId: item.id
						}
					})
					// this.$toast.fail("跳转到专家抽取模块，正在完善中。。。")
					return;
				} else if ('01' == item.projectStatus) {
					//
					this.$ls.set("tempRoute", "record")
					this.$router.push({
						name: 'step3',
						query: {
							fromPage: 'resume',
							projectId: item.id
						}
					})
					//
					// this.$toast.fail("跳转到专家抽取模块，正在完善中。。。");
					return;
				} else if ('02' == item.projectStatus) {

				} else if ('03' == item.projectStatus) {

				} else {
					sweetAlert("", '项目状态错误，无法查看！', "error");
					return;
				}
				this.$router.push('/projectInfo')
			},
			//搜索项目
			searchProject() {
				this.list = [];
				this.pageParams.pageNo = 1;
				this.onLoad();
			},
			refreshPage() {
				window.location.reload()
			},
			//点击清除按钮后触发
			onClear() {
				this.queryParam.projectName = '';
				this.list = [];
				this.pageParams.pageNo = 1;
				this.onLoad();
			},
			//下一个方法
		}
	}
</script>

<style scoped>
	.whiteBg {
		padding: 0.3rem;
		position: relative;
		margin-top: 0.3rem;
		border-radius: 0.2rem;
	}

	.flex-item>p {
		width: calc(100% - 2.05rem);
	}

	.addBtn {
		background-color: white;
		color: #00CCB2;
		font-weight: bold;
		border: 1px solid #00CCB2;
		padding: 0.1rem 0.5rem;
		border-radius: 0.5rem;
		margin-top: 0.3rem;
	}
</style>
